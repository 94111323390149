import { AppState, useAuth0 } from '@auth0/auth0-react'
import { usePostHog } from 'posthog-js/react'

import { apiLogout } from '../api/User'

import {
  LOGOUT_REDIRECT_PATH,
  PREMIUM_LEGACY_LOGIN_URL,
  USE_JWT_AUTH
} from '../utils/Constants'

export interface PremiumAuth {
  isAuthLoading: boolean
  isAuthenticated: boolean
  login: () => Promise<void>
  getAccessToken: () => Promise<string>
  logout: () => Promise<void>
}

/**
 * Provides login, logout, and access token functionality for premium users.
 *
 *  Handles legacy login and logout flows.
 *
 * TODO: Generalize this hook and move it to /common
 */
export const usePremiumAuth = (): PremiumAuth => {
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    loginWithRedirect: auth0Login,
    getAccessTokenSilently: getAccessToken,
    logout: auth0Logout
  } = useAuth0()

  const posthog = usePostHog()

  const login = async () => {
    if (USE_JWT_AUTH) {
      console.log('Redirecting to Auth0 login')
      // Include the current URL in the login request so that after login,
      // we can redirect the user to the page they were on before logging in.
      const appState: AppState = {
        returnTo: window.location.href
      }
      await auth0Login({ appState })
    } else {
      console.log('Redirecting to Auth0 login (legacy)')
      // Legacy login method that redirects to the backend
      window.location.href = PREMIUM_LEGACY_LOGIN_URL
    }
  }

  const logout = async () => {
    // Legacy logout endpoint. Even when using JWT auth, we still
    //  need to call this endpoint because it's currently the only
    //  way to logout after impersonating a user as an admin.
    console.log('Logging out (legacy)')
    apiLogout().then((respData) => {
      posthog.reset()

      if (USE_JWT_AUTH) {
        // If using bearer auth, we can safely ignore the redirect URL
        // returned from the legacy logout endpoint since we log out from
        // Auth0 directly from the frontend.
        console.log('Logging out (Auth0)')
        const returnTo = `${window.location.origin}${LOGOUT_REDIRECT_PATH}`
        console.log(`Will return to ${returnTo} after logout`)
        auth0Logout({ returnTo })
      } else {
        // If not using bearer auth, we need to redirect to the legacy logout
        // endpoint to logout from Auth0.
        console.log('(Legacy) Redirecting to post-logout URL')
        const logoutUrl = respData?.url
        if (logoutUrl) {
          window.location.href = logoutUrl
        }
      }
    })
  }

  return { isAuthLoading, isAuthenticated, login, getAccessToken, logout }
}
