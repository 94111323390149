import React from 'react'

import { ECommercePlatforms } from '@hypotenuse/common/src/components/digitalShelfAnalytics/interfaces'
import {
  CatalogueTemplateMappingKeyType,
  Product
} from '@hypotenuse/common/src/interfaces/Products'
import type {
  FeatureFlags,
  UserCompanySizeLabel,
  UserOnboardingSelectedFeatures,
  UserRole,
  UserRoleLabel,
  UserWorkLabel
} from '@hypotenuse/common/src/utils/Interfaces'
import {
  AppFeatures,
  LongFormContentLengthOption
} from '@hypotenuse/common/src/utils/Interfaces'

// Re-export interfaces from common
export {
  Currency,
  PlanBillingPeriod,
  PlanType,
  StripeSessionMode
} from '@hypotenuse/common/src/utils/Interfaces'
export type {
  BlogGenConfig,
  ContentBrandingConfig,
  Dialog,
  InputFieldMappingConfig,
  OrganizationInfo,
  OrganizationUsers,
  PlanTier as PremiumPlanTier,
  PlanTierList as PremiumPlanTierList,
  ServiceConfigs,
  User,
  UserOnboardingInfoForm
} from '@hypotenuse/common/src/utils/Interfaces'
export { AppFeatures }

export interface Credits {
  left: number
  total: number
  monthly: number
}

export interface CatalogueIdName {
  catalogueId: string
  catalogueName: string
}

export interface UrlParams extends Record<string, string> {
  page: string
  catId: string
}

export enum PlanChangeType {
  new_subscription = 'new_subscription',
  monthlyToMonthlyUpgrade = 'monthly_to_monthly_upgrade',
  monthlyToMonthlyDowngrade = 'monthly_to_monthly_downgrade',
  monthlyToAnnualUpgrade = 'monthly_to_annual_upgrade',
  monthlyToAnnualDowngrade = 'monthly_to_annual_downgrade',
  annualToMonthlyUpgrade = 'annual_to_monthly_upgrade',
  annualToMonthlyDowngrade = 'annual_to_monthly_downgrade',
  annualToAnnualUpgrade = 'annual_to_annual_upgrade',
  annualToAnnualDowngrade = 'annual_to_annual_downgrade',
  cancellation = 'cancellation',
  resume_subscription = 'resume_subscription'
}

export enum ProductMetadataErrorType {
  create = 'Create metadata validation error',
  update = 'Update metadata validation error'
}

export enum GenerateProductErrorType {
  empty_fields = 'empty_fields',
  title_too_long = 'title_too_long',
  brand_too_long = 'brand_too_long',
  tags_too_long = 'tags_too_long'
}

export enum GenerateWriterErrorType {
  no_writers = 'no_writers',
  no_brand_or_channel = 'no_brand_or_channel'
}

export enum StripePaymentFailureErrorType {
  payment_declined = 'payment_declined',
  need_user_confirmation = 'need_user_confirmation'
}

export enum TabKey {
  new = 'new',
  generated = 'generated',
  reviewing = 'reviewing',
  saved = 'saved',
  all = 'all'
}

export const ProductTabLabel = {
  [TabKey.new]: 'New',
  [TabKey.generated]: 'Generated',
  [TabKey.reviewing]: 'For Review',
  [TabKey.saved]: 'Done',
  [TabKey.all]: 'All'
}

export enum GenerationStatusFilterKey {
  all = 'all',
  generated = 'generated',
  not_generated = 'not_generated',
  generation_error = 'generation_error'
}

export const GenerationStatusFilterLabel = {
  [GenerationStatusFilterKey.all]: 'All',
  [GenerationStatusFilterKey.generated]: 'Generated',
  [GenerationStatusFilterKey.not_generated]: 'Not Generated',
  [GenerationStatusFilterKey.generation_error]: 'Failed to Generate'
}

export interface CatalogueFilter {
  catalogueId?: string
  tab?: TabKey
  generationStatus?: GenerationStatusFilterKey
  search?: string
  brand?: Array<string>
  tags?: Array<string>
  category?: Array<string>
  startDate?: Date
  endDate?: Date
  scan?: boolean
  sortBy?: (string | Record<string, any>)[] | undefined
  shopifyStoreNameList?: string[]
}

export interface ProductSearchQuery extends CatalogueFilter {
  start?: number
  end?: number
}

export interface ProductRelatedKeywordsQuery {
  search_term: string
  country_iso_code?: string
  search_depth?: number
  product_id: string
  num_of_related_keywords?: number
  platform_to_scrape: ECommercePlatforms
}

/**
 * All available export modes
 */
export type ExportMode = 'all' | 'tab' | 'query'

/**
 * Additional information about an export mode
 */
export interface ExportModeInfo {
  exportMode: ExportMode
  label: React.ReactNode
}

/**
 * Format of the export request sent to the backend
 * */
export interface ExportFilters {
  query: ProductSearchQuery
  includeUnselectedDescriptions: boolean
  includeProductsWithoutSelectedDescription: boolean
  /** Flag to use edited fields in the export instead of original data in input file (title, brand, tags etc.) */
  includeLatestProductFields: boolean
}

export enum ExportFormat {
  csv = 'csv',
  xlsx = 'xlsx'
}

export type ShopifyExportTargetField = 'description_html' | 'seo_description'
export const EXPORT_TARGET_FIELDS: ShopifyExportTargetField[] = [
  'description_html',
  'seo_description'
]

export interface ShopifyExportMapping {
  shopify_field: string[]
}

export enum ProductDedupeMode {
  ignore = 'ignore',
  overwrite = 'overwrite',
  update = 'update'
  // both = 'both'
}

/**
 * Set of configurable parameters for catalogue.
 *
 * These parameters are used to generate product descriptions.
 */
export interface CatalogueGenerationConfig {
  /**
   * Number of words to generate for
   * each product description in the
   * catalogue.
   */
  word_count: number
}

export interface Catalogue {
  catalogueId: string
  catalogueName: string
  template_id?: string
  products: Array<Product>
  numProducts: number
  tags: Set<string>
  keywordsToAvoid: Set<string>
  writers: Array<string>
  generation_config?: CatalogueGenerationConfig
  style_influences?: string[]
  selected_tone_ids?: string[]
  selected_country_id?: string
  current_seo_suggest_job_id?: string
  current_bulk_enrich_from_web_job_id?: string
  current_bulk_enrich_from_url_job_id?: string
  current_bulk_enrich_from_image_job_id?: string
  current_bulk_enrich_from_upc_job_id?: string
}

export interface CatalogueOverviewResponse extends CatalogueIdName {
  createdAt: string
  numberOfProducts: number
  tags: Array<string>
  keywordsToAvoid: Array<string>
}

/**
 * catalogueIds: Get the catalogues specified in catalogueIds
 * allowedCatalogueIds: Used internally to limit the catalogues that the query is allowed to get
 * search: Used to query by each catalogue's name and tags as well as the product's name, brand and sku
 * scan: Used to determine pagination. If true, will return every catalogue. If false, used with start and end
 * sortBy: Currently sorting by 'score', 'createdAt' and 'catalogueId'. Score is how relevant the row/document is.
 */
export interface CatalogueOverviewSearchQuery {
  catalogueIDs?: Array<string>
  allowedCatalogueIds?: Array<string>
  search?: string
  scan?: boolean
  sortBy?: Array<CatalogueOverviewSortBy>
  start?: number
  end?: number
}

export enum CatalogueOverviewSortBy {
  SCORE = 'score',
  CREATED_AT = 'createdAt',
  CATALOGUE_ID = 'catalogueId'
}

export interface CatalogueUpdateForm {
  catalogueId: string
  catalogueName?: string
  products?: Array<Product>
  tags?: string[]
  keywordsToAvoid?: string[]
  writers?: Array<string>
  generation_config?: CatalogueGenerationConfig
  style_influences?: string[]
  selected_tone_ids?: string[]
  selected_country_id?: string
}

export interface CatalogueHeadersSet {
  headers: Set<string>
  required_headers: Set<string>
}

export interface CatalogueHeadersResponse {
  headers: string[]
  required_headers: string[]
}

export interface FieldMappingDetails {
  /**
   * Display name of the particular field.
   * If undefined, the default name will be used.
   */
  display_name?: string
  /**
   * Whether the field is a required field
   */
  is_required?: boolean
  /**
   * Help text to display to the user. This can be used to provide additional information about the field.
   */
  help_text?: string
}

export interface CatalogueTemplate {
  id: string
  display_name: string
  input_mapping: Partial<
    Record<CatalogueTemplateMappingKeyType, FieldMappingDetails>
  >
  show_enrich_from_web?: boolean
  /**
   * List of writer name groups that correspond to this catalogue template.
   * This list of writer name groups will be filtered based on user's writer permissions to determine
   * which writers ultimately get shown to the user.
   */
  writer_name_groups: string[]
  /**
   * List of tone ids that correspond to this catalogue template.
   */
  tone_ids: string[]
}

export interface TabInfo {
  key: TabKey | keyof typeof TabKey // Allows either `ProductTab.all` or `'all'`
  count?: number
  label: string
  loading?: boolean
  hasNew?: boolean
}

export interface FilterValueInfo {
  label: string
  key: string
}

export interface CategoryInfo {
  key: string
  label: string
  group?: string
}

export enum CancellationReason {
  difficult_to_use = 'difficult_to_use',
  i_cant_afford = 'i_cant_afford',
  missing_features_i_need = 'missing_features_i_need',
  dont_need_it_anymore = 'dont_need_it_anymore',
  bad_content_quality = 'bad_content_quality',
  will_be_back = 'will_be_back',
  others = 'others',
  // Legacy field
  only_needed_it_once = 'only_needed_it_once', // Changed to dont_need_it_anymore
  didnt_have_time_to_use = 'didnt_have_time_to_use', // Changed to dont_need_it_anymore
  found_a_better_tool = 'found_a_better_tool', // Removed
  too_expensive = 'too_expensive' // Changed to i_cant_afford
}

export const CancellationReasonLabel: {
  [key in CancellationReason]: string
} = {
  [CancellationReason.difficult_to_use]: 'Difficult to use',
  [CancellationReason.i_cant_afford]: 'I can’t afford',
  [CancellationReason.missing_features_i_need]: 'Missing features I need',
  [CancellationReason.dont_need_it_anymore]: 'Don’t need it anymore',
  [CancellationReason.bad_content_quality]: 'Bad content quality',
  [CancellationReason.will_be_back]: 'Will be back',
  [CancellationReason.others]: 'Others',
  // Legacy fields
  [CancellationReason.only_needed_it_once]: 'Only needed it once', // Changed to dont_need_it_anymore
  [CancellationReason.didnt_have_time_to_use]: "Didn't have time to use", // Changed to dont_need_it_anymore
  [CancellationReason.found_a_better_tool]: 'Found a better tool', // Removed
  [CancellationReason.too_expensive]: 'Too expensive' // Changed to i_cant_afford
}

export interface CancellationPlanForm {
  cancellation_reason: CancellationReason
  cancellation_description?: string
}

export interface PremiumFAQ {
  question: string
  answer: string
}

export interface StripeBillingInfo {
  subscription_info: StripeSubscriptionInfo
  payment_info: StripePaymentInfo
  past_charges: StripeCharge[]
}

export interface StripeCharge {
  amount: number
  created: number
  receipt_url?: string
  description?: string
  status: StripeChargeStatus
  currency: string
  refunded: boolean
}

export enum StripeChargeStatus {
  succeeded = 'succeeded',
  failed = 'failed',
  pending = 'pending'
}

export interface StripeCardDetails {
  brand: string
  last4: string
  exp_month: string
  exp_year: string
  country: string
}

export interface StripePaymentOwnerInfo {
  name?: string
  email?: string
}

export interface StripePaymentInfo {
  card?: StripeCardDetails
  owner: StripePaymentOwnerInfo
}

export enum StripeCouponDuration {
  once = 'once',
  forever = 'forever',
  repeating = 'repeating'
}

export interface StripeCoupon {
  valid: boolean
  percent_off?: number
  amount_off?: number
  duration: StripeCouponDuration
  duration_in_months?: number
}

export interface StripeDiscount {
  coupon: StripeCoupon
}

export enum StripeSubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  past_due = 'past_due',
  unpaid = 'unpaid',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing'
}

export interface StripeSubscriptionInfo {
  has_past_subscription: boolean
  has_active_subscription: boolean
  subscription_start_date?: Date
  next_billing_date?: Date
  discount?: StripeDiscount
  status: StripeSubscriptionStatus
}

export type CatalogueTransportFormat = 'csv' | 'shopify' | 'xlsx'

export interface UnmatchedHeaders {
  extra_headers: string[]
  missing_headers: string[]
}

export interface CatalogueTransportFormatInfo {
  format: CatalogueTransportFormat
  label: string
}

export interface NavBarItem {
  key: string
  /**
   * Text to render on the front end for this particular item
   */
  text: React.ReactNode
  /**
   * Route to redirect the user to when click on this nav bar item.
   */
  value: string
  /**
   * Whether the item is hidden from the user
   */
  hidden?: boolean
}

export interface APIKey {
  key: string
  username: string
  time_created: Date
  time_last_accessed?: Date
  is_active: boolean
}

export type DescriptionColor =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'olive'
  | 'green'
  | 'teal'
  | 'violet'
  | 'purple'
  | 'pink'
  | undefined

export interface ProductDetailsFormValidation {
  title: string
  brand: string
  // this is a string and not a list
  // because this is the type of the error
  // not the actual field
  tags: string
  seoKeywords: string
  existingDescription: string
  existingBulletPoints: string
  avoidKeywords: string
  image: string
  retailer: string
}

export interface GlobalAppState {
  catalogues: Record<string, CatalogueOverviewResponse>
  cataloguesList: Array<CatalogueOverviewResponse>
  catTopThreeProducts: Partial<Record<string, Array<Product> | undefined>>
  catalogues_loading: boolean
  defaultDownvoteReasons: string[]
  brandPlaceholderText: string
  brandHelperText: string
  blogToneOptions: string[]
  blogLengthOptions: LongFormContentLengthOption[]
  globalFeatureFlags: FeatureFlags
}

export enum ProductType {
  fashion = 'fashion',
  home_living = 'home_living',
  hobbies = 'hobbies',
  electronics = 'electronics',
  others = 'others'
}

export const ProductTypeLabel: Record<ProductType, string> = {
  [ProductType.fashion]: 'Fashion',
  [ProductType.home_living]: 'Home and Living',
  [ProductType.hobbies]: 'Hobbies',
  [ProductType.electronics]: 'Electronics',
  [ProductType.others]: 'Others'
}

type ValueOf<T> = T[keyof T]

export const AppFeaturesSubTitle: Record<AppFeatures, React.ReactNode> = {
  [AppFeatures.product_feature]:
    'Batch generate SEO friendly product descriptions.',
  [AppFeatures.blog_feature]:
    'Go from idea to published in a streamlined workflow.',
  [AppFeatures.ads_feature]:
    'Explore all templates, from rewriting to landing pages.',
  [AppFeatures.image_gen_feature]:
    'Generate stunning images.\nExpress ideas. Stand out.',
  [AppFeatures.insta_caption]: 'Instagram Captions',
  [AppFeatures.headline]: 'Headlines',
  [AppFeatures.rewrite]: 'Rewriter',
  [AppFeatures.summarise]: 'Extract key points and insights',
  [AppFeatures.ask_anything]: 'Ask AI anything',
  [AppFeatures.hypo_chat]: 'Easily create content by just chatting with AI.',
  [AppFeatures.website_content]: 'Website content',
  [AppFeatures.social_posts]: 'Social posts',
  [AppFeatures.other]: 'Other',
  [AppFeatures.hypodoc]: (
    <>
      <span>Upload a PDF</span>
      <br />
      <span>to ask AI anything about it.</span>
    </>
  )
}

export interface AppFeaturesItem {
  title: React.ReactNode
  image: (props: {
    isSelected: boolean
    isDisabled: boolean
  }) => React.ReactNode
  formField: AppFeatures
  formFieldValue: boolean | undefined
}

export interface UserRoleItem {
  title: string
  subTitle: string
  image: string | undefined
  formField: UserRole
}
export interface UserUpdateForm {
  displayName: string
  creditsLeft?: number
  creditsTotal?: number
  creditsMonthly?: number
}

export enum OnboardingInputs {
  userDisplayName = 'userDisplayName',
  userOrganizationDisplayName = 'userOrganizationDisplayName',
  userRole = 'userRole',
  productTypeState = 'productTypeState',
  userOthers = 'userOthers',
  productTypeOthers = 'productTypeOthers',
  aboutUs = 'aboutUs',
  userWebsite = 'userWebsite',
  userWork = 'userWork',
  userCompanySize = 'userCompanySize',
  userWorkOthers = 'userWorkOthers'
}

export interface OnboardingFormInputs extends UserOnboardingSelectedFeatures {
  [OnboardingInputs.userDisplayName]: string
  [OnboardingInputs.userOrganizationDisplayName]: string
  [OnboardingInputs.userRole]: ValueOf<typeof UserRoleLabel>[] | []
  [OnboardingInputs.productTypeState]: Record<string, boolean>
  [OnboardingInputs.userOthers]: string
  [OnboardingInputs.productTypeOthers]: string
  [OnboardingInputs.aboutUs]: string
  [OnboardingInputs.userWebsite]?: string
  [OnboardingInputs.userWork]: ValueOf<typeof UserWorkLabel> | ''
  [OnboardingInputs.userCompanySize]: ValueOf<typeof UserCompanySizeLabel> | ''
  [OnboardingInputs.userWorkOthers]?: string
}

export class MissingProductImagesError extends Error {
  /**
   * Used when trying to upload CSV and Images together to a catalog, but some of the products in CSV did not have
   * images successfully uploaded for them inside the Images zip file
   */
  public missingProductImages: string[]

  constructor(message: string, missingProductImages: string[]) {
    super(message)
    this.missingProductImages = missingProductImages
  }
}

export class NoImageUploadedError extends Error {
  /**
   * Used when there are no images in the zip file uploaded that was matched and saved to any product in the catalog
   */
}
