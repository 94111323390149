import { create, createStore } from 'zustand'

import { handleTrackEnterpriseFeatureModalOpen } from '../api/Analytics'
import { apiFetchPlanTierListObject } from '../api/PlanTier'

import { EnterpriseNudgeFeature } from '../utils/Interfaces'

export interface EnterpriseFeatureModalProps {
  /**
   * Source element triggering the nudge. Used for tracking.
   */
  elementId: string
  /**
   * The type of enterprise feature to nudge for.
   */
  featureName: EnterpriseNudgeFeature
  /**
   * The window location of the element triggering the nudge. Used for tracking.
   *
   * If left unspecified, defaults to `window.location.pathname`.
   */
  pathname?: string
  /**
   * The function to be called when the user clicks on the CTA button on the enterprise feature modal.
   */
  handleCTAClick?: () => void
}

interface IEnterpriseFeatureModalStore {
  /**
   * Whether the modal is open or not
   */
  open: boolean
  /**
   * The corresponding feature for the enterprise feature modal
   * This will be used to determine the content of the modal
   * @example EnterpriseNudgeFeature.ProductDetailsEnrichment
   */
  featureName?: EnterpriseNudgeFeature
  /**
   * The function to be called when the user clicks on the CTA button on the enterprise feature modal.
   */
  handleCTAClick?: () => void
  /**
   * Opens the modal and sets the props to be sent to the modal
   */
  openModal: (modalProps: EnterpriseFeatureModalProps) => void
  /**
   * Closes the modal and resets the props
   */
  closeModal: () => void
}

const enterpriseFeatureModalStore = createStore<IEnterpriseFeatureModalStore>(
  (set) => ({
    open: false,
    closeModal: () => set({ open: false }),

    openModal: (modalProps: EnterpriseFeatureModalProps) => {
      // Props destructuring
      const {
        elementId,
        featureName,
        pathname: _pathname,
        handleCTAClick
      } = modalProps
      const pathname = _pathname || window.location.pathname
      set({
        open: true,
        featureName: featureName,
        handleCTAClick: handleCTAClick
      })

      // Track feature modal open event
      apiFetchPlanTierListObject().then((planTierList) => {
        const planListId = planTierList.plan_list_id
        handleTrackEnterpriseFeatureModalOpen({
          planListId: planListId,
          elementId: elementId,
          pathname: pathname
        })
      })
    }
  })
)

export const useEnterpriseFeatureModal = create(enterpriseFeatureModalStore)
