import React, { useEffect, useRef } from 'react'

import { useAnalytics } from '@hypotenuse/common/src/analytics/Analytics'
import UnexpectedError from '@hypotenuse/common/src/components/error/UnexpectedError'

/**
 * Component that's rendered as a fallback at error boundaries
 */
export const ErrorFallback: React.FC = () => {
  const { trackEvent } = useAnalytics()
  const documentTitles = document.getElementsByTagName('title')

  /**
   * Checks for translaton attempts in browsers.
   *
   * It checks the DOM for evidence of if machine translation
   * and prompts users to disable their translation service and/or file a request.
   *
   * @see https://martijnhols.nl/gists/how-to-detect-google-translate-and-other-machine-translation
   */
  const isTranslationError =
    (documentTitles && documentTitles[0]?.hasAttribute('_msttexthash')) || // Microsoft Edge
    document.documentElement.lang !== 'en' // All other browsers

  const hasSentEventRef = useRef<boolean>(false) // Ref to prevent sending analytics event multiple times
  useEffect(() => {
    if (!hasSentEventRef.current) {
      trackEvent(
        'user',
        `Error Encountered${
          isTranslationError
            ? ' (potentially due to enabled translation service)'
            : ''
        }`
      )
      hasSentEventRef.current = true
    }
  }, [isTranslationError, trackEvent])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh'
      }}
    >
      <UnexpectedError translationEnabled={isTranslationError} />
    </div>
  )
}

export default ErrorFallback
