import sanitizeFileName from 'sanitize-filename'

import snackbar from '../../utils/Snackbar'

/**
 * Downloads an asset to the user's local device.
 *
 * @param downloadLink The URL to download the asset from.
 * @param fileName The full name of the file.
 */
export const downloadAsset = async (downloadLink: string, fileName: string) => {
  const file = await fetch(downloadLink)
  const fileBlob = await file.blob()
  const fileURL = URL.createObjectURL(fileBlob)

  const link = document.createElement('a')
  link.href = fileURL
  link.download = sanitizeFileName(fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const copyAssetLinkToClipboard = async (downloadLink: string) => {
  await navigator.clipboard.writeText(downloadLink)
  snackbar.show('Copied asset link to clipboard!', {
    variant: 'success',
    autoHideDuration: 2000
  })
}

export const userLocale = navigator.languages?.[0] ?? 'en-us'
